import { captureException } from '@sentry/nextjs'
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'
import { useCallback, useEffect, useState } from 'react'
import { isLocalEnv, isProductionEnv, isSafari } from 'src/helpers'

import {
  GoogleLoginButton,
  MicrosoftLoginButton,
  SsoLoginButton,
} from './LoginButtons'

export type AuthProvider =
  | { type: 'google' }
  | { type: 'microsoft' }
  | { type: 'saml'; id: string }

interface Props {
  providers: AuthProvider[]
}

const FirebaseAuth = ({ providers }: Props) => {
  const [error, setError] = useState('')
  const auth = getAuth()

  const handleError = useCallback((error: any) => {
    if (error.code === 'auth/account-exists-with-different-credential') {
      setError(
        'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'
      )
    } else if (error.code === 'auth/invalid-credential') {
      setError('The supplied auth credential is malformed or has expired.')
    } else if (error.code === 'auth/user-disabled') {
      setError('The user account has been disabled.')
    } else {
      setError(error.message || 'An error occured')
    }
    captureException(error)
  }, [])

  useEffect(() => {
    getRedirectResult(auth)
      .then(arg => {
        // eslint-disable-next-line no-debugger
        debugger
        console.log('getRedirectResult', arg)
      })
      .catch(handleError)
  }, [auth, handleError])

  const handleAuthClick = useCallback(
    (authProvider: AuthProvider) => {
      auth.useDeviceLanguage()
      let provider:
        | GoogleAuthProvider
        | OAuthProvider
        | SAMLAuthProvider
        | undefined
      if (authProvider.type === 'google') {
        provider = new GoogleAuthProvider()
        provider.setCustomParameters({
          prompt: 'select_account',
        })
      } else if (authProvider.type === 'microsoft') {
        const oauthProvider = new OAuthProvider('microsoft.com')
        oauthProvider.setCustomParameters({
          prompt: 'select_account',
        })
        oauthProvider.addScope('email')
        oauthProvider.addScope('openid')
        provider = oauthProvider
      } else if (authProvider.type === 'saml') {
        provider = new SAMLAuthProvider(authProvider.id)
      }
      if (!provider) return

      let signInMethod: typeof signInWithRedirect | typeof signInWithPopup =
        signInWithRedirect

      if (!isProductionEnv()) {
        // Cross-domain restrictions prevents Firebase redirect auth flow
        // to work in most browsers for our preview and local env.
        //
        // See <https://github.com/firebase/firebase-js-sdk/issues/6716>
        // and <https://stackoverflow.com/a/70024211>.
        //
        // This is an issue for Safari both locally and in preview, and for
        // other browsers only in the local env.
        //
        // To mitigate with we use the popup flow which doesn't have this issue.
        // If you disable third-party-storage-partitioning rewrite will work
        // Tests disable security so redirect works, and is more reliable
        // than popup
        if (isSafari() || isLocalEnv()) {
          signInMethod = signInWithPopup
        }
      }

      signInMethod(getAuth(), provider).catch(handleError)
    },
    [auth, handleError]
  )

  const buttons = providers.map(p => {
    switch (p.type) {
      case 'google':
        return (
          <GoogleLoginButton
            key={p.type}
            onClick={() => handleAuthClick(p)}
          ></GoogleLoginButton>
        )
      case 'microsoft':
        return (
          <MicrosoftLoginButton
            key={p.type}
            onClick={() => handleAuthClick(p)}
          ></MicrosoftLoginButton>
        )
      case 'saml':
        return (
          <SsoLoginButton
            key={p.type}
            onClick={() => handleAuthClick(p)}
          ></SsoLoginButton>
        )
    }
  })

  return (
    <div className='flex flex-col items-stretch'>
      {error && (
        <div className='flex bg-critical-surface p-4 text-sm mt-6 rounded-lg justify-center'>
          <p className='text-critical-action'>{error}</p>
        </div>
      )}

      <div className='flex flex-col items-center text-gray-500 mt-6 gap-4'>
        {buttons}

        <p className='text-xs mt-6 px-4 text-center'>
          By continuing, you are indicating that you accept our{' '}
          <a
            href='https://www.arcade.software/tos-privacy-policy'
            className='link text-gray-900'
            target='_blank'
            rel='noreferrer'
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href='https://arcade.software/privacy'
            className='link text-gray-900'
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default FirebaseAuth
